import React from "react"
import showdown from "showdown"


const Content = ({ children }) => {
  const html = new showdown.Converter().makeHtml(children);

  return (
    <div
      className="content"
      dangerouslySetInnerHTML={{ __html: html }}>
    </div>
  )
}

export default Content
