import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Row, Col } from 'react-bootstrap'


const BlogRoll = () => {
  const data = useStaticQuery(graphql `
    query BlogRollQuery {
      allStrapiWilma19Post(sort: {order: DESC, fields: date}) {
        edges {
          node {
            slug
            title
            content { data { content } }
            teaser
            date(formatString: "DD.MM.YYYY", locale: "de")
            featuredImage {
              caption
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 800, placeholder: TRACED_SVG, webpOptions: {quality: 95})
                }
              }
            }
          }
        }
      }
    }
  `)
  
  return (
    <>
      {data.allStrapiWilma19Post.edges.map(post => (
        <Link to={'/' + post.node.slug} key={post.node.slug}>
          <Row className="post-preview mb-5">
            <Col md={3} className="mt-5 mt-md-3">
              <GatsbyImage
                image={post.node.featuredImage.localFile.childImageSharp.gatsbyImageData}
                alt={`Image`}
                layout="fullWidth"
                style={{ maxHeight: 200 }}
              />
            </Col>
            <Col md={9} className="mt-3">
              <small>{post.node.date}</small>
              <h3>
                {post.node.title}
              </h3>
              <span className={post.node.teaser?.length > 300 ? 'small-teaser-font' : ''}>
                {(post.node.teaser || post.node.content.data.content?.substring(0, 200).replace(/(<([^>]+)>)/gi, " ") + '...') + " ▸"}
              </span>
            </Col>
          </Row>
        </Link>
      ))}
    </>
  )
}

export default BlogRoll
